import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const OrderHistory = () => {
  // Sample data for demonstration purposes
  const [orders] = useState([
    {
      id: "ORD001",
      date: "01/10/2023",
      total: "$100",
      status: "Delivered",
    },
    {
      id: "ORD002",
      date: "10/10/2023",
      total: "$200",
      status: "Shipped",
    },
  ]);

  // If you fetch the orders from an API, you can do it in this useEffect.
  useEffect(() => {
    // fetchOrdersFromApi or similar can go here
  }, []);

  return (
    <div className="bg-gray-100 p-8">
      <h2 className="text-2xl font-semibold mb-6">Order History</h2>

      <div className="bg-white p-6 rounded-md shadow-md">
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b">Order ID</th>
              <th className="px-4 py-2 border-b">Date</th>
              <th className="px-4 py-2 border-b">Total</th>
              <th className="px-4 py-2 border-b">Status</th>
              <th className="px-4 py-2 border-b">Details</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td className="px-4 py-2 border-b">{order.id}</td>
                <td className="px-4 py-2 border-b">{order.date}</td>
                <td className="px-4 py-2 border-b">{order.total}</td>
                <td className="px-4 py-2 border-b">{order.status}</td>
                <td className="px-4 py-2 border-b">
                  <Link to={`/order/${order.id}`} className="text-blue-500 hover:text-blue-600">View</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderHistory;
