import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { logoLight } from "../../assets/images";
import { useRegistration } from "../context/RegistrationContext";
import { useNavigate } from "react-router-dom";

const SignUp = () => {


  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message
  const navigate = useNavigate();


  const {
    firstname,
    setFirstName,
    lastname,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    confirm_password,
    setConfirmPassword,
    accepted_tos,
    setAccepted_tos,
    registerUser,
  } = useRegistration();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleAcceptedTOSChange = (event) => {
    setAccepted_tos(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirm_password) {
      console.log("Passwords do not match.");
      return;
    }

    if (!accepted_tos) {
      console.log("Please accept the terms of service.");
      return;
    }
    try {
      const formData = {
        firstname,
        lastname,
        email,
        password,
        confirm_password,
        accepted_tos,
      };
      await registerUser(formData);

      setShowSuccessMessage(true); // Show success message
      navigate("/");
    } catch (error) {
      console.log("Registration failed. Please try again later.");
      console.error(error);
    }
  };

  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const handleShowPass = () => {
    if (showPass === false) {
      setShowPass(true);
    } else {
      setShowPass(false);
    }
  };

  const handleShowPassConfirm = () => {
    if (showPassConfirm === false) {
      setShowPassConfirm(true);
    } else {
      setShowPassConfirm(false);
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-start">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Get started for free
            </h1>
            <p className="text-base">Create your account to access more</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with GetBlack
              </span>
              <br />
              Dive deep, explore the vastness, and make the most of everything GetBlack has to offer. 
              Your journey to excellence starts here! 🖤🚀
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all GetBlack services
              </span>
              <br />
              We’re proud to offer a diverse range of services tailored to meet your every need. 
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
               We make it incredibly easy to shop and get your desired traditional products delivered to you.
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              © GetBlack
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      {showSuccessMessage ? (
        <div className="success_message">Registration Successful! You can now login.</div>
      ) :
        (
          <form className="w-full lgl:w-[500px] h-screen flex items-center justify-center" onSubmit={handleSubmit}>
            <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
            <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
              Create Account
            </h1>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-.5">
              <p className="font-titleFont text-base font-semibold text-gray-600">
                    First Name
                  </p>
              <label>
              <input
                className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                type="text"
                placeholder="First Name"
                value={firstname}
                onChange={handleFirstNameChange}
              />
              </label>
              </div>
              <div className="flex flex-col gap-.5">
              <p className="font-titleFont text-base font-semibold text-gray-600">
                    Last Name
                  </p>
              <label>
              <input
                className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                type="text"
                placeholder="Last Name"
                value={lastname}
                onChange={handleLastNameChange}
              />
              </label>
              </div>
              <div className="flex flex-col gap-.5">
              <p className="font-titleFont text-base font-semibold text-gray-600">
                    Email
                  </p>
              <label>
              <input
                className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
              />
              </label>
              </div>
              
            <div className="flex flex-col gap-.5">
              <p className="font-titleFont text-base font-semibold text-gray-600">
                  Password
              </p>

            <label className="password">
              <input
                className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                type={showPass ? "text" : "password"}
                name="password_visibility" onClick={handleShowPass} 
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button className="password_visibility" onClick={handleShowPass}>
                {showPass ? "hide" : "show"}
              </button>
            </label>
            </div>

             
            <div className="flex flex-col gap-.5">
              <p className="font-titleFont text-base font-semibold text-gray-600">
                  Confirm Password
              </p>
            <label className="password">
              <input
                className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                type={showPassConfirm ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirm_password}
                onChange={handleConfirmPasswordChange}
              />
              <button
                className="password_visibility"
                onClick={handleShowPassConfirm}
              >
                {showPassConfirm ? "hide" : "show"}
              </button>
            </label>
            </div>

            <div className="flex items-start mdl:items-center gap-2">
              <input
                type="checkbox"
                checked={accepted_tos}
                onChange={handleAcceptedTOSChange}
              />
                <p className="text-sm text-primeColor">
                    I agree to the GetBlack 
                    <span className="text-blue-500">Terms of Service </span>and 
                    <span className="text-blue-500">Privacy Policy</span>.
                </p>
            </div>

            <button 
              className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"             type="submit">
              Register
            </button>
            <p className="text-sm text-center font-titleFont font-medium">
                  Have an Account? 
                  <Link to="/login">
                    <span className="hover:text-blue-600 duration-300">
                      Sign in
                    </span>
                  </Link>
                </p>
            </div>
            </div>
          </form>
        )}
    </div>
  );
};


export default SignUp;
