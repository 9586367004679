import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuth } from '../context/AuthContext'; 
import { useCart } from '../context/CartContext';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { user } = useAuth();
  const { cartItem } = useCart();

  const calculateTotalAmount = () => {
    return cartItem.reduce((price, item) => price + item.qty * item.price, 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      return;
    }

    const cartInfo = cartItem.map(item => ({
      title: item.name,
      price: item.price,
      image: item.product_images[0].product_image_url,
      qty: item.qty
    }));

    const paymentData = {
      user_id: user.id,
      cart: cartInfo,
      total_amount: calculateTotalAmount(),
    };

    const response = await fetch('https://getblackapi-drf-e7648734260e.herokuapp.com/api/checkout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paymentData),
    });

    if (response.ok) {
      setSuccess(true);
      setError(null);
    } else {
      setError('Error processing payment.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <label className="block">
        <span className="text-gray-700">Card details</span>
        <div className="mt-2">
          <CardElement className="p-2 border rounded" />
        </div>
      </label>
      {error && <div className="text-red-500">{error}</div>}
      {success && <div className="text-green-500">Payment successful!</div>}
      <button 
        type="submit" 
        disabled={!stripe}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 active:bg-blue-700"
      >
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
