import React, { createContext, useContext, useState, useEffect } from 'react';

// Providing default values for the context
const CartContext = createContext({
  cartItem: [],
  addToCart: () => {},
  decreaseQty: () => {},
  clearCart: () => {}
});

const CartProvider = ({ children }) => {
  const [cartItem, setCartItem] = useState([]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItem'));
    if (storedCartItems) {
      setCartItem(storedCartItems);
    }
  }, []);

  // Save cart items to local storage whenever cartItems changes
  useEffect(() => {
    localStorage.setItem('cartItem', JSON.stringify(cartItem));
  }, [cartItem]);

  const addToCart = (product) => {
    const productExists = cartItem.find((item) => item.id === product.id);
    if (productExists) {
      setCartItem(cartItem.map((item) => 
        item.id === product.id ? { ...productExists, qty: productExists.qty + 1 } : item)
      );
    } else {
      setCartItem([...cartItem, { ...product, qty: 1 }]);
    }
  };

  const decreaseQty = (product) => {
    const productExists = cartItem.find((item) => item.id === product.id);
    if (productExists.qty === 1) {
      setCartItem(cartItem.filter((item) => item.id !== product.id));
    } else {     
      setCartItem(cartItem.map((item) => 
        item.id === product.id ? { ...productExists, qty: productExists.qty - 1 } : item)
      );
    }
  };

  const clearCart = () => {
    setCartItem([]);
  };

  const value = {
    cartItem,
    addToCart,
    decreaseQty,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export { CartContext, CartProvider, useCart };
