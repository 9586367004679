import React, { useState, useEffect } from 'react';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    // Fetch orders from API here or use mock data
    const mockOrders = [
      { id: 1, customer: 'John Doe', items: 3, total: '$100', status: 'Pending' },
      { id: 2, customer: 'Jane Smith', items: 2, total: '$60', status: 'Shipped' },
      // Add more mock orders...
    ];

    setOrders(mockOrders);
  }, []);

  const markAsShipped = (orderId) => {
    // In a real-world scenario, you'd send a request to the backend
    const updatedOrders = orders.map((order) =>
      order.id === orderId ? { ...order, status: 'Shipped' } : order,
    );
    setOrders(updatedOrders);
  };

  return (
    <div className='p-8'>
      <h2 className='text-2xl font-bold mb-4'>Order Management</h2>
      <table className='min-w-full bg-white'>
        <thead>
          <tr>
            <th className='py-2 px-4 border-b border-gray-200 bg-gray-100'>Order ID</th>
            <th className='py-2 px-4 border-b border-gray-200 bg-gray-100'>Customer</th>
            <th className='py-2 px-4 border-b border-gray-200 bg-gray-100'>Items</th>
            <th className='py-2 px-4 border-b border-gray-200 bg-gray-100'>Total</th>
            <th className='py-2 px-4 border-b border-gray-200 bg-gray-100'>Status</th>
            <th className='py-2 px-4 border-b border-gray-200 bg-gray-100'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td className='py-2 px-4 border-b border-gray-200'>{order.id}</td>
              <td className='py-2 px-4 border-b border-gray-200'>{order.customer}</td>
              <td className='py-2 px-4 border-b border-gray-200'>{order.items}</td>
              <td className='py-2 px-4 border-b border-gray-200'>{order.total}</td>
              <td className='py-2 px-4 border-b border-gray-200'>{order.status}</td>
              <td className='py-2 px-4 border-b border-gray-200'>
                {order.status === 'Pending' && (
                  <button
                    onClick={() => markAsShipped(order.id)}
                    className='bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none'
                  >
                    Mark as Shipped
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderManagement;
