import React, { useContext, useState } from 'react';

const VendorContext = React.createContext();

const VendorProvider = ({ children }) => {
    const [business_name, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postal_code, setPostalCode] = useState('');
    const [profile_picture, setProfilePicture] = useState(null);
    const [agree_to_terms, setAgree_to_terms] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');


    const registerVendor = async () => {
        const formData = new FormData();
    
        formData.append('business_name', business_name);
        formData.append('email', email);
        formData.append('phone_number', phone_number);
        formData.append('password', password);
        formData.append('address', address);
        formData.append('city', city);
        formData.append('country', country);
        formData.append('postal_code', postal_code);
        if (profile_picture) {
            formData.append('profile_picture', profile_picture, profile_picture.name);
        }
        formData.append('agree_to_terms', agree_to_terms);
    
        try {
            setSuccessMessage('Registration successful!');
            setError('');
        } catch (error) {
            if (error.response) {
                setError(error.response.data.message || 'Registration failed. Please try again later.');
            } else if (error.request) {
                setError('Registration failed. No response received from server.');
            } else {
                setError('Error', error.message);
            }
        }
    };
    
    


    const contextValues = {
        business_name,
        setBusinessName,
        email,
        setEmail,
        phone_number,
        setPhoneNumber,
        password,
        setPassword,
        address,
        setAddress,
        city,
        setCity,
        country,
        setCountry,
        postal_code,
        setPostalCode,
        profile_picture,
        setProfilePicture,
        agree_to_terms,
        setAgree_to_terms,
        registerVendor,
        successMessage,
        error,
    };



    return (
        <VendorContext.Provider value={ contextValues }>
            {children}
        </VendorContext.Provider>
    );
};

// const useVendorContext = () => useContext(VendorContext);

function useVendorContext() {
    return useContext(VendorContext);
}

export { VendorContext, VendorProvider, useVendorContext };