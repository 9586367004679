import React, { useState } from 'react';

const VendorSettings = () => {
  const [storeName, setStoreName] = useState('');
  const [description, setDescription] = useState('');
  const [bankDetails, setBankDetails] = useState('');

  const handleSaveChanges = (e) => {
    e.preventDefault();
    // ... your saving logic
  };

  return (
    <div className='vendor-settings p-8 bg-white rounded shadow-lg max-w-md mx-auto mt-10'>
      <h2 className='text-2xl font-bold mb-4'>Vendor Settings</h2>

      <form onSubmit={handleSaveChanges}>
        <div className='input-group mb-4'>
          <label className='block text-sm font-semibold mb-2'>Store Name:</label>
          <input
            type='text'
            className='p-2 border rounded w-full'
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          />
        </div>

        <div className='input-group mb-4'>
          <label className='block text-sm font-semibold mb-2'>Description:</label>
          <textarea
            className='p-2 border rounded w-full h-24'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className='input-group mb-4'>
          <label className='block text-sm font-semibold mb-2'>Bank Details:</label>
          <input
            type='text'
            className='p-2 border rounded w-full'
            value={bankDetails}
            onChange={(e) => setBankDetails(e.target.value)}
          />
        </div>

        {/* Add other settings as required */}

        <button
          type='submit'
          className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50'
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default VendorSettings;
