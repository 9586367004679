import React, { useState, useEffect } from 'react';

const UserNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://getblackapi-drf-e7648734260e.herokuapp.com/api/notification/')
      .then((response) => response.json())
      .then((data) => {
        setNotifications(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className='relative'>
      <button onClick={() => setShow(!show)} className='bg-gray-300 px-4 py-2 rounded'>
        Notifications ({isLoading ? 'Loading...' : notifications.length})
      </button>

      {show && (
        <div className='absolute right-0 mt-2 w-80 bg-white border border-gray-200 rounded shadow-lg'>
          {notifications.length === 0 ? (
            <p className='p-4'>No new notifications.</p>
          ) : (
            notifications.map((notif) => (
              <div key={notif.id} className='border-b border-gray-200'>
                <p className='p-4'>
                  {notif.text}
                  <br />
                  <span className='text-gray-500 text-sm'>{notif.date}</span>
                </p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default UserNotifications;
