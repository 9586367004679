import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs';

const Journal = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState('');
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className='max-w-container mx-auto px-4'>
      <Breadcrumbs title='Journal' prevLocation={prevLocation} />
      <div className='pb-10'>
        <h1 className='max-w-[600px] text-base text-lightText mb-2'>
          <span className='text-primeColor font-semibold text-lg'>
            🚀 **GetReady, GetSet, #GetBlack! 🖤**
          </span>

          <p>
            🚀 **GetReady, GetSet, #GetBlack! 🖤** Hey [Community/Followers/Friends]! We are
            thrilled to announce that something BLACK and BOLD is on the horizon. 🌌 From our
            passion to your screens, the most awaited experience is about to drop. #GetBlack is not
            just a product, it is a movement, it is a feeling, it is what you have been waiting for.
            🎉 🔜 Why all the suspense? Because good things take time... and this, we promise, is
            worth every second of your wait! 👀 Sneak peeks? Behind-the-scenes? Exclusive early
            access? Stay tuned! Hit that notification bell/subscribe button/follow us now so you
            don not miss out. Spread the word, ignite the anticipation and let everyone know that it is
            time to #GetBlack! 🖤 Launching soon. Stay Black. Stay Bold. 📅 Mark your calendars. 🔗
            Keep an eye on our page. 🚀 And get ready to be amazed! #LaunchingSoon #StayTuned
            #ExcitingTimesAhead 🚀🖤🎉
          </p>
        </h1>
        <Link to='/shop'>
          <button className='w-52 h-10 bg-primeColor text-white hover:bg-black duration-300'>
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Journal;
