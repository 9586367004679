import React, { useState, useEffect } from 'react';
import {
  FaCog,
  FaShoppingCart,
  FaCcMastercard,
  FaCommentAlt,
  FaBorderStyle,
  FaSignOutAlt,
} from 'react-icons/fa';
import { MdOutlineAnalytics, MdDashboard } from 'react-icons/md';
import VendorOrders from './VendorOrders';
import VendorProduct from './VendorProduct';
import VendorSettings from './VendorSettings';
import VendorStatistics from './VendorStatistics';
import VendorTransactions from './VendorTransactions';
import VendorReviews from './VendorReviews';

import { useDispatch, useSelector } from 'react-redux';
import { removeVendorInfo, removeVendorToken } from '../../redux/vendorSlice';
import { Link } from 'react-router-dom';

const VendorDashboard = () => {
  // const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selection, setSelection] = useState('dashboard');
  const [vendorToken, setVendorToken] = useState('');
  const [vendorInfo, setVendorInfo] = useState({});

  const vt = useSelector((state) => state.vendorReducer.vendorToken);
  const vi = useSelector((state) => state.vendorReducer.vendorInfo);

  useEffect(() => {
    if (vt && vi) {
      setVendorToken(vt);
      setVendorInfo(vi);
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (vt && vi && vendorToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const dispatch = useDispatch();

  const handleRemoveVendorInfo = () => {
    // Dispatch the action to remove vendor info
    dispatch(removeVendorInfo());
    dispatch(removeVendorToken());
    setVendorToken('');
    setVendorInfo({});
    setIsLoggedIn(false);
  };

  return (
    <div className='bg-gray-100 min-h-screen flex'>
      {isLoggedIn ? (
        <div className='w-full flex'>
          {/* Sidebar */}
          <div className='bg-white w-64 min-h-screen border-r'>
            <div className='flex items-center justify-center h-20 shadow-md'>
              <img
                src={vendorInfo ? vendorInfo.profile_picture : '#'}
                alt='User Profile'
                className='w-12 h-12 rounded-full mr-4'
              />
              <h1 className='text-2xl font-semibold'>
                {vendorInfo ? vendorInfo.business_name : 'Vendor'}
              </h1>
            </div>
            <ul>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'dashboard' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                  <MdDashboard className='mr-4' />
                  <button onClick={() => setSelection('vendor-dashboard')} className='block py-2.5 px-4'>
                    Dashboard
                  </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'products' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaShoppingCart className='mr-4' />
                <button onClick={() => setSelection('products')} className='block py-2.5 px-4'>
                  Products
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'orders' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaBorderStyle className='mr-4' />
                <button onClick={() => setSelection('orders')} className='block py-2.5 px-4'>
                  Orders
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'statistics' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <MdOutlineAnalytics className='mr-4' />
                <button onClick={() => setSelection('statistics')} className='block py-2.5 px-4'>
                  Statistics
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'reviews' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaCommentAlt className='mr-4' />
                <button onClick={() => setSelection('reviews')} className='block py-2.5 px-4'>
                  Reviews
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'transactions' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaCcMastercard className='mr-4' />
                <button onClick={() => setSelection('transactions')} className='block py-2.5 px-4'>
                  Transactions
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'settings' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaCog className='mr-4' />
                <button onClick={() => setSelection('settings')} className='block py-2.5 px-4'>
                  Settings
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 text-red-500 hover:text-red-700 hover:font-bold text-lg flex items-center py-2.5 px-4`}
              >
                <FaSignOutAlt className='mr-4' />
                <button onClick={handleRemoveVendorInfo} className='block py-2.5 px-4 font-bold'>
                  Sign Out
                </button>
              </li>
            </ul>
          </div>

          {/* Main Content */}
          <div className='flex-1 p-10 font-bold'>
            {/* {selection === 'profile' && <UserProfile />} */}
            {selection === 'settings' && <VendorSettings />}
            {selection === 'order' && <VendorOrders />}
            {selection === 'products' && <VendorProduct />}
            {selection === 'review' && <VendorReviews />}
            {selection === 'transactions' && <VendorTransactions />}
            {selection === 'statistics' && <VendorStatistics />}
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center w-full h-full gap-4'>
          <p className='text-green-600 text-lg'>You are not signed in!</p>
          <Link to='/vendorsignin'>
            <button className='bg-green-500 hover:bg-blue-500 text-white font-bold px-4 py-2 rounded-md duration-500 cursor-pointer'>
              Sign In
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default VendorDashboard;
