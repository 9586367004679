import React, { useState, useEffect } from 'react';

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    // Typically, you'd fetch this data from your backend API
    const mockTransactions = [
      {
        id: 1,
        date: '2023-09-25',
        amount: '$150.00',
        invoiceUrl: '/path-to-invoice-1.pdf',
      },
      {
        id: 2,
        date: '2023-09-20',
        amount: '$45.00',
        invoiceUrl: '/path-to-invoice-2.pdf',
      },
    ];

    setTransactions(mockTransactions);
  }, []);

  return (
    <div className='transactions'>
      <h2>Your Payment History</h2>
      <table className='min-w-full bg-white'>
        <thead>
          <tr>
            <th className='w-1/3 py-2 px-4 border-b'>Date</th>
            <th className='w-1/3 py-2 px-4 border-b'>Amount</th>
            <th className='w-1/3 py-2 px-4 border-b'>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td className='py-2 px-4 border-b'>{transaction.date}</td>
              <td className='py-2 px-4 border-b'>{transaction.amount}</td>
              <td className='py-2 px-4 border-b'>
                <a href={transaction.invoiceUrl} download>
                  Download Invoice
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Transactions;
