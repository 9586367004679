import React, { useState, useEffect } from 'react';
import {
  FaCog,
  FaShoppingCart,
  FaCcMastercard,
  FaCommentAlt,
  FaBorderStyle,
  FaSignOutAlt,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineAnalytics, MdDashboard } from 'react-icons/md';
import { useForm, Controller } from 'react-hook-form';
import { GiCheckMark } from 'react-icons/gi';
// import UploadProduct from './UploadProduct';
// import ProductSpecs from './ProductSpecs';

import { useDispatch, useSelector } from 'react-redux';
import { removeVendorInfo, removeVendorToken } from '../../redux/vendorSlice';
import { Link } from 'react-router-dom';

const ProductSpecPage = () => {
  // const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selection, setSelection] = useState('products');
  const [vendorToken, setVendorToken] = useState('');
  const [vendorInfo, setVendorInfo] = useState({});

  const vt = useSelector((state) => state.vendorReducer.vendorToken);
  const vi = useSelector((state) => state.vendorReducer.vendorInfo);

  useEffect(() => {
    if (vt && vi) {
      setVendorToken(vt);
      setVendorInfo(vi);
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (vt && vi && vendorToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const dispatch = useDispatch();

  const handleRemoveVendorInfo = () => {
    // Dispatch the action to remove vendor info
    dispatch(removeVendorInfo());
    dispatch(removeVendorToken());
    setVendorToken('');
    setVendorInfo({});
    setIsLoggedIn(false);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [lowerSubcategories, setLowerSubcategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const selectedCategory = watch('category'); // Watch the 'category' field for changes
  const selectedSubcategory = watch('subcategory'); // Watch the 'subcategory' field for changes
  const isOnline = watch('is_online');
  const isOnSale = watch('is_on_sale');
  const isInStock = watch('is_in_stock');
  const isReturnable = watch('is_returnable');
  const isPerishable = watch('is_perishable');
  const isFeatured = watch('is_featured');
  const selectedProductType = watch('product_type');

  // https://getblackapi-drf-e7648734260e.herokuapp.com/api/
  // https://getblackapi-drf-e7648734260e.herokuapp.com/api/

  useEffect(() => {
    // Fetch the product categories from the API here
    // For example, using fetch:
    fetch('https://getblackapi-drf-e7648734260e.herokuapp.com/api/category')
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    // Fetch the subcategories based on the selected category
    // For example, using fetch:
    if (selectedCategory) {
      fetch(`https://getblackapi-drf-e7648734260e.herokuapp.com/api/category/subcategory`)
        .then((response) => response.json())
        .then((data) => {
          // Filter the subcategories based on the selected category
          const filteredSubcategories = data.filter(
            (subcategory) => subcategory.category.id === Number(selectedCategory),
          );

          setSubcategories(filteredSubcategories);
        })
        .catch((error) => console.error('Error fetching subcategories:', error));
    } else {
      // If no category is selected, reset the subcategories
      setSubcategories([]);
      setValue('subcategory', ''); // Reset the value of 'subcategory' field
    }
  }, [selectedCategory, setValue]);

  useEffect(() => {
    // Fetch the subcategories based on the selected category
    // For example, using fetch:
    if (selectedSubcategory) {
      fetch(`https://getblackapi-drf-e7648734260e.herokuapp.com/api/category/lower-subcategory`)
        .then((response) => response.json())
        .then((data) => {
          // Filter the subcategories based on the selected category
          const filteredLowerSubcategories = data.filter(
            (lower_subcategory) => lower_subcategory.subcategory.id === Number(selectedSubcategory),
          );
          setLowerSubcategories(filteredLowerSubcategories);
        });
    } else {
      // If no subcategory is selected, reset the lower subcategories
      setLowerSubcategories([]);
      setValue('lower_subcategory', ''); // Reset the value of 'subcategory' field
    }
  }, [selectedSubcategory, setValue]);

  useEffect(() => {
    // Fetch the product types from the API here
    // For example, using fetch:
    fetch('https://getblackapi-drf-e7648734260e.herokuapp.com/api/product/product-types/')
      .then((response) => response.json())
      .then((data) => setProductTypes(data))
      .catch((error) => console.error('Error fetching product types:', error));
  }, []);

  // useEffect(() => {
  //   console.log(selectedProductType);
  // }, [selectedProductType]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append each form field value to the FormData object
    formData.append('vendor', vi.id);
    formData.append('category', data.category);
    formData.append('subcategory', data.subcategory);
    formData.append('lower_subcategory', data.lower_subcategory);
    formData.append('product_type', data.product_type);
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('price', data.price);
    formData.append('is_on_sale', data.is_on_sale);
    formData.append('sales_price', data.sales_price);
    formData.append('is_featured', data.is_featured);

    if (data.product_type === '2') {
      formData.append('weight', data.weight);
      formData.append('is_in_stock', data.is_in_stock);
      formData.append('is_returnable', data.is_returnable);
    }
    if (data.product_type === '1') {
      formData.append('is_perishable', data.is_perishable);
      formData.append('storage_instructions', data.storage_instructions);
      formData.append('preparation_time', data.preparation_time);
      formData.append('requirements', data.ingredients);
      formData.append('expiry_date', data.expiry_date);
    }
    if (data.product_type === '3') {
      console.log(data.digital_product_file);
      formData.append('digital_product_file', data.digital_product_file[0]);
    }
    if (data.product_type === '4') {
      formData.append('is_online', data.is_online);
      formData.append('delivery_method', data.delivery_method);
      formData.append('requirements', data.requirements);
      formData.append('online_duration', data.online_duration);
    }
    if (data.product_type === '5') {
      formData.append('location', data.location);
      formData.append('materials_provided', data.materials_provided);
      formData.append('additional_requirements', data.additional_requirements);
      formData.append('offline_duration', data.offline_duration);
    }

    formData.append('meta_keywords', data.meta_keywords);
    formData.append('meta_description', data.meta_description);

    // Now, for attribute_values, you can iterate over them and append each value
    // for (let i = 0; i < data.attribute_values.length; i++) {
    //   formData.append("attribute_values", data.attribute_values[i]);
    // }

    try {
      // Make a POST request to your API endpoint
      const response = await fetch(
        'https://getblackapi-drf-e7648734260e.herokuapp.com/api/product/create/',
        {
          method: 'POST',
          body: formData,
        },
      );

      if (response.ok) {
        // Handle successful response
        toast.success('Product uploaded successfully!');
        console.log('Product uploaded successfully!');
        reset();
        // navigate('/vendor-dashboard');
      } else {
        // Handle error response
        console.log(response);
        console.error('Error submitting form data:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <div className='bg-gray-100 min-h-screen flex'>
      {isLoggedIn ? (
        <div className='w-full flex'>
          {/* Sidebar */}
          <div className='bg-white w-64 min-h-screen border-r'>
            <div className='flex items-center justify-center h-20 shadow-md'>
              <img
                src={vendorInfo ? vendorInfo.profile_picture : '#'}
                alt='User Profile'
                className='w-12 h-12 rounded-full mr-4'
              />
              <h1 className='text-2xl font-semibold'>
                {vendorInfo ? vendorInfo.business_name : 'Vendor'}
              </h1>
            </div>
            <ul>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'dashboard' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <Link to='vendor-dashboard' className='flex items-center justify-center'>
                  <MdDashboard className='mr-4' />
                  <button className='block py-2.5 px-4'>Dashboard</button>
                </Link>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'products' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <Link to='/vendor-dashboard' className='flex items-center justify-center'>
                  <FaShoppingCart className='mr-4' />
                  <button className='block py-2.5 px-4'>Products</button>
                </Link>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'orders' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaBorderStyle className='mr-4' />
                <button onClick={() => setSelection('orders')} className='block py-2.5 px-4'>
                  Orders
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'statistics' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <MdOutlineAnalytics className='mr-4' />
                <button onClick={() => setSelection('statistics')} className='block py-2.5 px-4'>
                  Statistics
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'reviews' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaCommentAlt className='mr-4' />
                <button onClick={() => setSelection('reviews')} className='block py-2.5 px-4'>
                  Reviews
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'transactions' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaCcMastercard className='mr-4' />
                <button onClick={() => setSelection('transactions')} className='block py-2.5 px-4'>
                  Transactions
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 hover:text-blue-500 flex items-center py-2.5 px-4 ${
                  selection === 'settings' ? 'text-yellow-600' : 'text-black'
                }`}
              >
                <FaCog className='mr-4' />
                <button onClick={() => setSelection('settings')} className='block py-2.5 px-4'>
                  Settings
                </button>
              </li>
              <li
                className={`hover:bg-gray-100 text-red-500 hover:text-red-700 hover:font-bold text-lg flex items-center py-2.5 px-4`}
              >
                <FaSignOutAlt className='mr-4' />
                <button onClick={handleRemoveVendorInfo} className='block py-2.5 px-4 font-bold'>
                  Sign Out
                </button>
              </li>
            </ul>
          </div>

          {/* Main Content */}
          <div className='flex flex-col p-10 w-full gap-16'>
            <h2 className='text-2xl font-bold'>Upload Product</h2>

            <form
              onSubmit={handleSubmit(onSubmit)}
              encType='multipart/form-data'
              className='flex flex-col items-start gap-6 w-1/2 mx-auto font-normal text-gray-700'
            >
              <label htmlFor='product_type' className='w-full relative'>
                <Controller
                  name='product_type'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please select a product product type' }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                    >
                      <option value='' disabled>
                        Select a product type
                      </option>
                      {productTypes.map((product_type) => (
                        <option key={product_type.id} value={product_type.id}>
                          {product_type.name}
                        </option>
                      ))}
                    </select>
                  )}
                />

                <div className='absolute left-0 top-full mt-2 w-full bg-white border-[1.5px] border-yellow-700 rounded-b-3xl z-10 hidden'>
                  {subcategories.map((product_type) => (
                    <div
                      key={product_type.id}
                      className='py-2 px-4 cursor-pointer hover:bg-gray-100'
                    >
                      {product_type.name}
                    </div>
                  ))}
                </div>
                {errors.product_type && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.product_type.message}</p>
                )}
              </label>

              <label htmlFor='category' className='w-full relative'>
                <Controller
                  name='category'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please select a product category' }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                    >
                      <option value='' disabled>
                        Select a category
                      </option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )}
                />

                <div className='absolute left-0 top-full mt-2 w-full bg-white border-[1.5px] border-yellow-700 rounded-b-3xl z-10 hidden'>
                  {categories.map((category) => (
                    <div key={category.id} className='py-2 px-4 cursor-pointer hover:bg-gray-100'>
                      {category.name}
                    </div>
                  ))}
                </div>

                {errors.category && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.category.message}</p>
                )}
              </label>

              <label htmlFor='subcategory' className='w-full relative'>
                <Controller
                  name='subcategory'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please select a product subcategory' }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                    >
                      <option value='' disabled>
                        Select a subcategory
                      </option>
                      {subcategories.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      ))}
                    </select>
                  )}
                />

                <div className='absolute left-0 top-full mt-2 w-full bg-white border-[1.5px] border-yellow-700 rounded-b-3xl z-10 hidden'>
                  {subcategories.map((subcategory) => (
                    <div
                      key={subcategory.id}
                      className='py-2 px-4 cursor-pointer hover:bg-gray-100'
                    >
                      {subcategory.name}
                    </div>
                  ))}
                </div>
                {errors.subcategory && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.subcategory.message}</p>
                )}
              </label>

              <label htmlFor='lower_subcategory' className='w-full relative'>
                <Controller
                  name='lower_subcategory'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please select a product lower subcategory' }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                    >
                      <option value='' disabled>
                        Select a lower subcategory
                      </option>
                      {lowerSubcategories.map((lower_subcategory) => (
                        <option key={lower_subcategory.id} value={lower_subcategory.id}>
                          {lower_subcategory.name}
                        </option>
                      ))}
                    </select>
                  )}
                />

                <div className='absolute left-0 top-full mt-2 w-full bg-white border-[1.5px] border-yellow-700 rounded-b-3xl z-10 hidden'>
                  {lowerSubcategories.map((lower_subcategory) => (
                    <div
                      key={lower_subcategory.id}
                      className='py-2 px-4 cursor-pointer hover:bg-gray-100'
                    >
                      {lower_subcategory.name}
                    </div>
                  ))}
                </div>
                {errors.lower_subcategory && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.lower_subcategory.message}</p>
                )}
              </label>

              <label htmlFor='name' className='w-full relative'>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please enter the product name' }}
                  render={({ field }) => (
                    <input
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                      type='text'
                      placeholder='Name'
                    />
                  )}
                />
                {errors.name && <p className='ml-4 mt-2 text-red-600'>{errors.name.message}</p>}
              </label>

              <label htmlFor='description' className='w-full relative'>
                <Controller
                  name='description'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please enter the product description' }}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-non overflow-x-hidden overflow-y-scroll'
                      rows={6}
                      placeholder='Description'
                    />
                  )}
                />
                {errors.description && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.description.message}</p>
                )}
              </label>

              <label htmlFor='price' className='w-full relative'>
                <Controller
                  name='price'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Please enter the product price' }}
                  render={({ field }) => (
                    <input
                      {...field}
                      type='number'
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                      placeholder='Price'
                    />
                  )}
                />
                {errors.price && <p className='ml-4 mt-2 text-red-600'>{errors.price.message}</p>}
              </label>

              <section className=''>
                <label htmlFor='is_on_sale' className='relative flex items-center gap-4'>
                  Is On Sale:
                  <span className='relative'>
                    <Controller
                      name='is_on_sale'
                      control={control}
                      defaultValue={false} // Set the default value to false (not on sale)
                      rules=''
                      render={({ field }) => (
                        <span className='flex items-center justify-center cursor-pointer duration-500'>
                          <input
                            {...field}
                            type='checkbox'
                            className='appearance-none w-10 h-8 absolute'
                          />
                          <span
                            className={`w-10 h-8 flex items-center justify-center rounded-md ${
                              isOnSale ? 'bg-yellow-500' : 'bg-white'
                            } border-yellow-500 border-[1.5px]`}
                          >
                            <GiCheckMark className='text-white font-extrabold' size={14} />
                          </span>
                        </span>
                      )}
                    />
                  </span>
                  {errors.is_on_sale && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.is_on_sale.message}</p>
                  )}
                </label>
              </section>

              {isOnSale && ( // Conditionally render the sales_price field
                <label htmlFor='sales_price' className='w-full relative'>
                  <Controller
                    name='sales_price'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please enter the sales price' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='number'
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        placeholder='Sales price'
                      />
                    )}
                  />
                  {errors.sales_price && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.sales_price.message}</p>
                  )}
                </label>
              )}

              <section className=''>
                <label htmlFor='is_featured' className='relative flex items-center gap-4'>
                  Is featured:
                  <span className='relative'>
                    <Controller
                      name='is_featured'
                      control={control}
                      defaultValue={false}
                      rules=''
                      render={({ field }) => (
                        <span className='flex items-center justify-center cursor-pointer duration-500'>
                          <input
                            {...field}
                            type='checkbox'
                            className='appearance-none w-10 h-8 absolute'
                          />
                          <span
                            className={`w-10 h-8 flex items-center justify-center rounded-md ${
                              isFeatured ? 'bg-yellow-500' : 'bg-white'
                            } border-yellow-500 border-[1.5px]`}
                          >
                            <GiCheckMark className='text-white font-extrabold' size={14} />
                          </span>
                        </span>
                      )}
                    />
                  </span>
                  {errors.is_featured && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.is_featured.message}</p>
                  )}
                </label>
              </section>

              {selectedProductType === '2' && (
                <label htmlFor='weight' className='w-full relative'>
                  <Controller
                    name='weight'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please enter the product weight' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='number'
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        placeholder='Weight'
                      />
                    )}
                  />
                  {errors.weight && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.weight.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '2' && (
                <section className='form__control'>
                  <label htmlFor='is_in_stock' className='relative flex items-center gap-4'>
                    Is In Stock:
                    <span className='relative'>
                      <Controller
                        name='is_in_stock'
                        control={control}
                        defaultValue={false} // Set the default value to false (not on sale)
                        rules=''
                        render={({ field }) => (
                          <span className='flex items-center justify-center cursor-pointer duration-500'>
                            <input
                              {...field}
                              type='checkbox'
                              className='appearance-none w-10 h-8 absolute'
                            />
                            <span
                              className={`w-10 h-8 flex items-center justify-center rounded-md ${
                                isInStock ? 'bg-yellow-500' : 'bg-white'
                              } border-yellow-500 border-[1.5px]`}
                            >
                              <GiCheckMark className='text-white font-extrabold' size={14} />
                            </span>
                          </span>
                        )}
                      />
                    </span>
                    {errors.is_in_stock && (
                      <p className='ml-4 mt-2 text-red-600'>{errors.is_in_stock.message}</p>
                    )}
                  </label>
                </section>
              )}

              {selectedProductType === '2' && (
                <section className='form__control'>
                  <label htmlFor='is_returnable' className='relative flex items-center gap-4'>
                    Is Returnable:
                    <span className='relative'>
                      <Controller
                        name='is_returnable'
                        control={control}
                        defaultValue={false} // Set the default value to false (not on sale)
                        rules=''
                        render={({ field }) => (
                          <span className='flex items-center justify-center cursor-pointer duration-500'>
                            <input
                              {...field}
                              type='checkbox'
                              className='appearance-none w-10 h-8 absolute'
                            />
                            <span
                              className={`w-10 h-8 flex items-center justify-center rounded-md ${
                                isReturnable ? 'bg-yellow-500' : 'bg-white'
                              } border-yellow-500 border-[1.5px]`}
                            >
                              <GiCheckMark className='text-white font-extrabold' size={14} />
                            </span>
                          </span>
                        )}
                      />
                    </span>
                    {errors.is_returnable && (
                      <p className='ml-4 mt-2 text-red-600'>{errors.is_returnable.message}</p>
                    )}
                  </label>
                </section>
              )}

              {selectedProductType === '1' && (
                <section className='form__control'>
                  <label htmlFor='is_perishable' className='relative flex items-center gap-4'>
                    Is Perishable:
                    <span className='relative'>
                      <Controller
                        name='is_perishable'
                        control={control}
                        defaultValue={false} // Set the default value to false (not on sale)
                        rules=''
                        render={({ field }) => (
                          <span className='flex items-center justify-center cursor-pointer duration-500'>
                            <input
                              {...field}
                              type='checkbox'
                              className='appearance-none w-10 h-8 absolute'
                            />
                            <span
                              className={`w-10 h-8 flex items-center justify-center rounded-md ${
                                isPerishable ? 'bg-yellow-500' : 'bg-white'
                              } border-yellow-500 border-[1.5px]`}
                            >
                              <GiCheckMark className='text-white font-extrabold' size={14} />
                            </span>
                          </span>
                        )}
                      />
                    </span>
                    {errors.is_perishable && (
                      <p className='ml-4 mt-2 text-red-600'>{errors.is_perishable.message}</p>
                    )}
                  </label>
                </section>
              )}

              {selectedProductType === '1' && (
                <label htmlFor='storage_instructions' className='w-full relative'>
                  <Controller
                    name='storage_instructions'
                    control={control}
                    defaultValue=''
                    rules=''
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none overflow-x-hidden overflow-y-scroll'
                        rows={3}
                        placeholder='Storage Instructions'
                      />
                    )}
                  />
                  {errors.storage_instructions && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.storage_instructions.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '1' && (
                <label htmlFor='preparation_time' className='w-full relative'>
                  <Controller
                    name='preparation_time'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please enter how long the food takes to prepare' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='text'
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        placeholder='Preparation Time e.g. 45 mins'
                      />
                    )}
                  />
                  {errors.preparation_time && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.preparation_time.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '1' && (
                <label htmlFor='ingredients' className='w-full relative'>
                  <Controller
                    name='ingredients'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please list the ingredients' }}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none overflow-x-hidden overflow-y-scroll'
                        rows={3}
                        placeholder='Ingredients'
                      />
                    )}
                  />
                  {errors.ingredients && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.ingredients.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '1' && (
                <label htmlFor='expiry_date' className='w-full relative'>
                  Expiry Date:
                  <Controller
                    name='expiry_date'
                    control={control}
                    defaultValue=''
                    rules=''
                    render={({ field }) => (
                      <input
                        {...field}
                        type='date'
                        className='w-full bg-white rounded-3xl mt-3 px-4 py-3 outline-none border-[1.5px] border-yellow-700'
                      />
                    )}
                  />
                  {errors.expiry_date && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.expiry_date.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '3' && (
                <label htmlFor='digital_product_file'>
                  Upload Digital Product(PDF):
                  <Controller
                    name='digital_product_file'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: 'Please select a PDF file',
                    }}
                    render={({ field }) => (
                      <input {...field} type='file' accept='application/pdf' />
                    )}
                    className='field__controller'
                  />
                  {errors.digital_product_file && (
                    <p className='field__error'>{errors.digital_product_file.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '4' && (
                <section className='form__control'>
                  <label htmlFor='is_online' className='relative flex items-center gap-4'>
                    Is Online:
                    <span className='relative'>
                      <Controller
                        name='is_online'
                        control={control}
                        defaultValue={false} // Set the default value to false (not on sale)
                        rules=''
                        render={({ field }) => (
                          <span className='flex items-center justify-center cursor-pointer duration-500'>
                            <input
                              {...field}
                              type='checkbox'
                              className='appearance-none w-10 h-8 absolute'
                            />
                            <span
                              className={`w-10 h-8 flex items-center justify-center rounded-md ${
                                isOnline ? 'bg-yellow-500' : 'bg-white'
                              } border-yellow-500 border-[1.5px]`}
                            >
                              <GiCheckMark className='text-white font-extrabold' size={14} />
                            </span>
                          </span>
                        )}
                      />
                    </span>
                    {errors.is_online && (
                      <p className='ml-4 mt-2 text-red-600'>{errors.is_online.message}</p>
                    )}
                  </label>
                </section>
              )}

              {selectedProductType === '4' && (
                <label htmlFor='delivery_method' className='w-full relative'>
                  <Controller
                    name='delivery_method'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please enter the online service delivery method' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        type='text'
                        placeholder='Delivery method'
                      />
                    )}
                  />
                  {errors.delivery_method && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.delivery_method.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '4' && (
                <label htmlFor='requirements' className='w-full relative'>
                  <Controller
                    name='requirements'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please list the requirements' }}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none overflow-x-hidden overflow-y-scroll'
                        rows={3}
                        placeholder='Requirements of the online service'
                      />
                    )}
                  />
                  {errors.requirements && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.requirements.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '4' && (
                <label htmlFor='online_duration' className='w-full relative'>
                  <Controller
                    name='online_duration'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: 'Please how long will the online service take you to complete?',
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='text'
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        placeholder='Duration e.g. 45 mins'
                      />
                    )}
                  />
                  {errors.online_duration && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.online_duration.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '5' && (
                <label htmlFor='location' className='w-full relative'>
                  <Controller
                    name='location'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Location is required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='text'
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        placeholder='Location'
                      />
                    )}
                  />
                  {errors.location && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.location.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '5' && (
                <label htmlFor='materials_provided' className='w-full relative'>
                  <Controller
                    name='materials_provided'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please list the materials provided for this service' }}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none overflow-x-hidden overflow-y-scroll'
                        rows={3}
                        placeholder='Materials provided for the offline service'
                      />
                    )}
                  />
                  {errors.materials_provided && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.materials_provided.message}</p>
                  )}
                </label>
              )}

              {selectedProductType === '5' && (
                <label htmlFor='additional_requirements' className='w-full relative'>
                  <Controller
                    name='additional_requirements'
                    control={control}
                    defaultValue=''
                    rules={{ required: 'Please list the additional requirements for this service' }}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none overflow-x-hidden overflow-y-scroll'
                        rows={3}
                        placeholder='Additional requirements for the offline service'
                      />
                    )}
                  />
                  {errors.additional_requirements && (
                    <p className='ml-4 mt-2 text-red-600'>
                      {errors.additional_requirements.message}
                    </p>
                  )}
                </label>
              )}

              {selectedProductType === '5' && (
                <label htmlFor='offline_duration' className='w-full relative'>
                  <Controller
                    name='offline_duration'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: 'Please how long will this offline service take you to complete?',
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='text'
                        className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                        placeholder='Duration e.g. 45 mins'
                      />
                    )}
                  />
                  {errors.offline_duration && (
                    <p className='ml-4 mt-2 text-red-600'>{errors.offline_duration.message}</p>
                  )}
                </label>
              )}

              <label htmlFor='meta_keywords' className='w-full relative'>
                <Controller
                  name='meta_keywords'
                  control={control}
                  defaultValue=''
                  rules=''
                  render={({ field }) => (
                    <input
                      {...field}
                      type='text'
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-none'
                      placeholder='SEO Keywords: separate with comma'
                    />
                  )}
                />
                {errors.meta_keywords && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.meta_keywords.message}</p>
                )}
              </label>

              <label htmlFor='meta_description' className='w-full relative'>
                <Controller
                  name='meta_description'
                  control={control}
                  defaultValue=''
                  rules=''
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className='w-full bg-white rounded-3xl px-4 py-3 outline-none border-[1.5px] border-yellow-700 appearance-non overflow-x-hidden overflow-y-scroll'
                      rows={4}
                      placeholder='SEO Description'
                    />
                  )}
                />
                {errors.meta_description && (
                  <p className='ml-4 mt-2 text-red-600'>{errors.meta_description.message}</p>
                )}
              </label>

              <button
                type='submit'
                className='bg-yellow-500 duration-500 rounded-full px-6 py-2 text-white hover:bg-green-500'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center w-full h-full gap-4'>
          <p className='text-green-600 text-lg'>You are not signed in!</p>
          <Link to='/vendorsignin'>
            <button className='bg-green-500 hover:bg-blue-500 text-white font-bold px-4 py-2 rounded-md duration-500 cursor-pointer'>
              Sign In
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProductSpecPage;