import React, { useState, useEffect } from 'react';
import { MdOutlineNotificationsActive  } from "react-icons/md";
import { FaCog, FaShoppingCart, FaUser, FaCcMastercard, } from 'react-icons/fa';
import UserProfile from "./UserProfile";
import UserSettings from "./UserSettings";
import UserOrderHistory from "./UserOrderHistory";
import UserNotification from "./UserNotification";
import UserTransactions from "./UserTransactions";




const UserDashboard = () => {
    const [userName, setUserName] = useState('Loading...');
    const [userProfilePic, setUserProfilePic] = useState('path_to_default_image.jpg'); // default image path
    const [selection, setSelection] = useState('profile');

    useEffect(() => {
        // Simulating fetching data from an API
        setTimeout(() => {
            setUserName(''); // fetched user name
            setUserProfilePic('path_to_fetched_image.jpg'); // fetched image path
        }, 1000);
    }, []);

    return (
        <div className="bg-gray-100 min-h-screen flex">
            {/* Sidebar */}
            <div className="bg-white w-64 min-h-screen border-r">
                <div className="flex items-center justify-center h-20 shadow-md">
                    <img src={userProfilePic} alt="User Profile" className="w-12 h-12 rounded-full mr-4" />
                    <h1 className="text-2xl font-semibold">{userName}</h1>
                </div>
                <ul>
                <li className="hover:bg-gray-100 flex items-center py-2.5 px-4">
                        <FaUser className="mr-4" />
                        <button onClick={()=>setSelection('profile')} className="block py-2.5 px-4 hover:text-blue-500">
                            Profile
                        </button>
                    </li>
                    <li className="hover:bg-gray-100 flex items-center py-2.5 px-4">
                        <FaCcMastercard className="mr-4" />
                        <button onClick={()=>setSelection('transactions')} className="block py-2.5 px-4 hover:text-blue-500">
                            Transactions
                        </button>
                    </li>
                    <li className="hover:bg-gray-100 flex items-center py-2.5 px-4">
                        <FaShoppingCart className="mr-4" />
                        <button onClick={()=>setSelection('order')} className="block py-2.5 px-4 hover:text-blue-500">
                            Order History
                        </button>
                    </li>
                    <li className="hover:bg-gray-100 flex items-center py-2.5 px-4">
                        <MdOutlineNotificationsActive className="mr-4" />
                        <button onClick={()=>setSelection('notification')} className="block py-2.5 px-4 hover:text-blue-500">
                            Notifications
                        </button>
                    </li>
                    <li className="hover:bg-gray-100 flex items-center py-2.5 px-4">
                        <FaCog className="mr-4" />
                        <button onClick={()=>setSelection('settings')} className="block py-2.5 px-4 hover:text-blue-500">
                            Settings
                        </button>
                    </li>
                </ul>
            </div>
            
            {/* Main Content */}
            <div className="flex-1 p-10 font-bold">
                {selection === 'profile' && <UserProfile />}
                {selection === 'settings' && <UserSettings />}
                {selection === 'order' && <UserOrderHistory />}
                {selection === 'notification' && <UserNotification />}
                {selection === 'transactions' && <UserTransactions />}
            </div>
        </div>
    );
}

export default UserDashboard;