import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendorInfo:
   { id: "",
    business_name: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    country: "",
    profile_picture: "",
 },
 vendorToken: "",
};

export const getblackVendorSlice = createSlice({
  name: "getblack",
  initialState,
  reducers: {
    addVendorInfo: (state, action) => {
        state.vendorInfo = action.payload;
    },
    removeVendorInfo: (state) => {
      state.vendorInfo = {}
    },
    addVendorToken: (state, action) => {
      state.vendorToken = action.payload;
    },
    removeVendorToken: (state) => {
      state.vendorToken = "";
    },
  },
});

export const {
  addVendorInfo,
  removeVendorInfo,
  addVendorToken,
  removeVendorToken,
} = getblackVendorSlice.actions;
export default getblackVendorSlice.reducer;
