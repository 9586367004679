import React, { useState, useEffect } from 'react';

const VendorStatistics = () => {
  const [statistics, setStatistics] = useState({
    totalSales: 0,
    topProducts: [],
    customersByLocation: {},
  });

  useEffect(() => {
    // Fetch statistics from API here or use mock data
    const mockData = {
      totalSales: 5000,
      topProducts: ['Product A', 'Product B', 'Product C'],
      customersByLocation: {
        USA: 200,
        Canada: 50,
        UK: 30,
      },
    };

    setStatistics(mockData);
  }, []);

  return (
    <div className='p-8'>
      <h2 className='text-2xl font-bold mb-6'>Vendor Statistics</h2>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
        {/* Total Sales */}
        <div className='p-6 bg-white shadow rounded'>
          <h3 className='text-lg font-semibold mb-4'>Total Sales</h3>
          <p className='text-3xl'>${statistics.totalSales}</p>
        </div>

        {/* Top Products */}
        <div className='p-6 bg-white shadow rounded'>
          <h3 className='text-lg font-semibold mb-4'>Top Products</h3>
          <ul>
            {statistics.topProducts.map((product) => (
              <li key={product} className='mb-2'>
                {product}
              </li>
            ))}
          </ul>
        </div>

        {/* Customers by Location */}
        <div className='p-6 bg-white shadow rounded'>
          <h3 className='text-lg font-semibold mb-4'>Customers by Location</h3>
          <ul>
            {Object.entries(statistics.customersByLocation).map(([location, count]) => (
              <li key={location} className='mb-2'>
                {location}: {count}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VendorStatistics;
