import React, { useState } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { logoLight } from '../../assets/images';
import { useVendorContext } from '../context/VendorContext';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Vendors = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message
  const [errorMessage, setErrorMessage] = useState('');
  // const navigate = useNavigate();

  const {
    business_name,
    setBusinessName,
    email,
    setEmail,
    phone_number,
    setPhoneNumber,
    password,
    setPassword,
    address,
    setAddress,
    city,
    setCity,
    country,
    setCountry,
    postal_code,
    setPostalCode,
    profile_picture,
    setProfilePicture,
    agree_to_terms,
    setAgree_to_terms
  } = useVendorContext();

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  const handleProfilePictureChange = (event) => {
    setProfilePicture(event.target.files[0]);
  };

  const handleAcceptedTOSChange = (event) => {
    setAgree_to_terms(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(''); // Clear previous error messages

    if (!agree_to_terms) {
      setErrorMessage('Please accept the terms of service.');
      return;
    }
    const formData = new FormData();

    // Append form data fields
    formData.append('business_name', business_name);
    formData.append('email', email);
    formData.append('phone_number', phone_number);
    formData.append('password', password);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('country', country);
    formData.append('postal_code', postal_code);

    // Check if profile_picture is an instance of the File class
    // (This ensures that you're actually appending a file and not something else)
    if (profile_picture instanceof File) {
      formData.append('profile_picture', profile_picture);
    } else {
      console.error(
        'profile_picture is not an instance of File. It might not have been captured from the file input properly.',
      );
    }

    // Convert agree_to_terms to string 'true' or 'false' and append
    // Ensure it's a boolean before converting
    if (typeof agree_to_terms === 'boolean') {
      formData.append('agree_to_terms', agree_to_terms ? 'true' : 'false');
    } else {
      console.error('agree_to_terms is not a boolean value.');
    }

    try {
      // Make an API POST request to the registration endpoint
      const response = await axios.post(
        'https://getblackapi-drf-e7648734260e.herokuapp.com/api/vendor/create/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if(response.data.error) {
        setShowSuccessMessage(false);
        alert(response.data.error)
      }

      // Check if the response contains data and handle it as needed
      if (response.data.success) {
        // console.log('Registration successful:', response.data);
        // setSuccessMessage('Registration successful');
        setBusinessName("")
        setEmail("")
        setPhoneNumber("")
        setPassword("")
        setAddress("")
        setCity("")
        setCountry("")
        setPostalCode("")
        setProfilePicture("")
        setAgree_to_terms("")
        setShowSuccessMessage(true);

        return response.data; // Return the response data as JSON
      } else {
        // Handle cases where the response does not contain data
        // setError('Response does not contain data');
        console.error('Response does not contain data');
        return null; // Or return an appropriate value
      }
    } catch (error) {
      // Handle API call errors
      // setError('Registration failed. Please try again.');
      console.error('Registration failed. Please try again.', error);
      throw error; // You can re-throw the error or handle it as needed
    }
  };

  const [showPass, setShowPass] = useState(false);

  const handleShowPass = () => {
    if (showPass === false) {
      setShowPass(true);
    } else {
      setShowPass(false);
    }
  };

  return (
    <div className='w-full h-screen flex items-center justify-start'>
      <div className='w-1/2 hidden lgl:inline-flex h-full text-white'>
        <div className='w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center'>
          <Link to='/'>
            <img src={logoLight} alt='logoImg' className='w-28' />
          </Link>
          <div className='flex flex-col gap-1 -mt-1'>
            <h1 className='font-titleFont text-xl font-medium'>Get started for free</h1>
            <p className='text-base'>Create your account to access more</p>
          </div>
          <div className='w-[300px] flex items-start gap-3'>
            <span className='text-green-500 mt-1'>
              <BsCheckCircleFill />
            </span>
            <p className='text-base text-gray-300'>
              <span className='text-white font-semibold font-titleFont'>
                Get started fast with GetBlack
              </span>
              <br />
              Dive deep, explore the vastness, and make the most of everything GetBlack has to
              offer. Your journey to excellence starts here! 🖤🚀
            </p>
          </div>
          <div className='w-[300px] flex items-start gap-3'>
            <span className='text-green-500 mt-1'>
              <BsCheckCircleFill />
            </span>
            <p className='text-base text-gray-300'>
              <span className='text-white font-semibold font-titleFont'>
                Access all GetBlack services
              </span>
              <br />
              We’re proud to offer a diverse range of services tailored to meet your every need.
            </p>
          </div>
          <div className='w-[300px] flex items-start gap-3'>
            <span className='text-green-500 mt-1'>
              <BsCheckCircleFill />
            </span>
            <p className='text-base text-gray-300'>
              <span className='text-white font-semibold font-titleFont'>
                Trusted by online Shoppers
              </span>
              <br />
              We make it incredibly easy to shop and get your desired traditional products delivered
              to you.
            </p>
          </div>
          <div className='flex items-center justify-between mt-10'>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              © GetBlack
            </p>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              Terms
            </p>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              Privacy
            </p>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              Security
            </p>
          </div>
        </div>
      </div>
      {showSuccessMessage ? (
        <div className='success_message'>
          
          <span className='text-green-500 text-lg font-bold'>Registration Successful! You can now </span>
          <Link to='/vendorsignin'>
          <button
                className='bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-fit text-base font-medium h-10 rounded-md px-4 ml-2 duration-300'
                type='submit'
              >
                Login
              </button>
                </Link></div>
      ) : (
        <form
          className='w-full lgl:w-[500px] h-screen flex items-center justify-center'
          onSubmit={handleSubmit}
        >
          <div className='px-6 py-4 w-full h-[96%] flex flex-col justify-start overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor'>
            <h1 className='font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4'>
              Register Your Business
            </h1>
            <h2>Please provide all the required details to register your business with us.</h2>
            {errorMessage && <p className='text-red-500'>{errorMessage}</p>}

            <div className='flex flex-col gap-3'>
              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>
                  Business Name
                </p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='text'
                    placeholder='Business Name'
                    value={business_name}
                    onChange={handleBusinessNameChange}
                  />
                </label>
              </div>

              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>Email</p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={handleEmailChange}
                  />
                </label>
              </div>

              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>Phone Number</p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='phone number'
                    placeholder='Your Phone Number'
                    value={phone_number}
                    onChange={handlePhoneNumberChange}
                  />
                </label>
              </div>

              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>Password</p>

                <label className='password'>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type={showPass ? 'text' : 'password'}
                    name='password_visibility'
                    onClick={handleShowPass}
                    placeholder='Password'
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <button className='password_visibility' onClick={handleShowPass}>
                    {showPass ? 'hide' : 'show'}
                  </button>
                </label>
              </div>

              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>Address</p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='text'
                    placeholder='Your Address'
                    value={address}
                    onChange={handleAddressChange}
                  />
                </label>
              </div>
              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>City</p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='text'
                    placeholder='Your City'
                    value={city}
                    onChange={handleCityChange}
                  />
                </label>
              </div>
              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>Country</p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='text'
                    placeholder='Your country'
                    value={country}
                    onChange={handleCountryChange}
                  />
                </label>
              </div>
              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>Postal Code</p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='text'
                    placeholder='postal code'
                    value={postal_code}
                    onChange={handlePostalCodeChange}
                  />
                </label>
              </div>
              <div className='flex flex-col gap-.5'>
                <p className='font-titleFont text-base font-semibold text-gray-600'>
                  Profile Photo
                </p>
                <label>
                  <input
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='file'
                    placeholder='Email'
                    // value={profile_picture}
                    onChange={handleProfilePictureChange}
                  />
                </label>
              </div>

              <div className='flex items-start mdl:items-center gap-2'>
                <input
                  type='checkbox'
                  checked={agree_to_terms}
                  onChange={handleAcceptedTOSChange}
                />
                <p className='text-sm text-primeColor'>
                  I agree to the GetBlack
                  <span className='text-blue-500'>Terms of Service </span>and
                  <span className='text-blue-500'>Privacy Policy</span>.
                </p>
              </div>

              <button
                className='bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300'
                type='submit'
              >
                Submit Registration
              </button>
              <p className='text-sm text-center font-titleFont font-medium'>
                Have an Account as a Vendor?
                <Link to='/vendorsignin'>
                  <span className='hover:text-blue-600 duration-300'>Sign in</span>
                </Link>
              </p>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Vendors;
