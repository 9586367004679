import React, { useState, useEffect } from 'react';

const ProductsTable = () => {
  const [products, setProducts] = useState([]); // List of products
  // const [productName, setProductName] = useState('');
  // const [productPrice, setProductPrice] = useState('');
  // const [setProductImage] = useState(null);
  // const [selectedProductId, setSelectedProductId] = useState(null); // For editing purposes

  useEffect(() => {
    // Fetch products from the backend
    // For the sake of this example, I'll use mock data.
    const mockProducts = [
      { id: 1, name: 'Product A', price: 100, imageUrl: 'path/to/imageA.jpg' },
      { id: 2, name: 'Product B', price: 200, imageUrl: 'path/to/imageB.jpg' },
    ];
    setProducts(mockProducts);
  }, []);

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setProductImage(file);
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Depending on whether you're editing or adding a new product,
  //   // you'll either update an existing product or add a new one.

  //   if (selectedProductId) {
  //     // Logic to update the product in the backend
  //   } else {
  //     // Logic to send data to the backend and save the new product
  //   }

  //   // Reset the form and selected product ID
  //   setProductName('');
  //   setProductPrice('');
  //   setProductImage(null);
  //   setSelectedProductId(null);
  // };

  // const handleEdit = (product) => {
  //   setProductName(product.name);
  //   setProductPrice(product.price);
  //   setSelectedProductId(product.id);
  // };

  // const handleDelete = () => {
  //   // Logic to delete product from the backend
  // };

  return (
    <div className='p-8'>
      

      <table className='min-w-full bg-white'>
        <thead>
          <tr>
            <th className='py-2 px-4 border-b'>Name</th>
            <th className='py-2 px-4 border-b'>Price</th>
            <th className='py-2 px-4 border-b'>Image</th>
            <th className='py-2 px-4 border-b'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td className='py-2 px-4 border-b'>{product.name}</td>
              <td className='py-2 px-4 border-b'>${product.price}</td>
              <td className='py-2 px-4 border-b'>
                <img src={product.imageUrl} alt={product.name} className='h-16' />
              </td>
              <td className='py-2 px-4 border-b'>
                <button
                  // onClick={() => handleEdit(product)}
                  className='mr-4 bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600'
                >
                  Edit
                </button>
                <button
                  // onClick={() => handleDelete(product.id)}
                  className='bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600'
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsTable;




// {
//   selection === 'upload-products' && <UploadProduct />;
// }
// {
//   selection === 'product-specs' && <ProductSpecs />;
// }

// <table className='min-w-full bg-white'>
//   <thead>
//     <tr>
//       <th className='py-2 px-4 border-b'>Name</th>
//       <th className='py-2 px-4 border-b'>Price</th>
//       <th className='py-2 px-4 border-b'>Image</th>
//       <th className='py-2 px-4 border-b'>Actions</th>
//     </tr>
//   </thead>
//   <tbody>
//     {/* {products.map((product) => (
//             <tr key={product.id}>
//               <td className='py-2 px-4 border-b'>{product.name}</td>
//               <td className='py-2 px-4 border-b'>${product.price}</td>
//               <td className='py-2 px-4 border-b'>
//                 <img src={product.imageUrl} alt={product.name} className='h-16' />
//               </td>
//               <td className='py-2 px-4 border-b'>
//                 <button
//                   onClick={() => handleEdit(product)}
//                   className='mr-4 bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600'
//                 >
//                   Edit
//                 </button>
//                 <button
//                   onClick={() => handleDelete(product.id)}
//                   className='bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600'
//                 >
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))} */}
//   </tbody>
// </table>;
