import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import LogIn from "./pages/Account/LogIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Cart/Checkout";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import Vendors from "./pages/Account/Vendors";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import VendorSignIn from "./pages/Account/VendorSignIn";
import VendorDashboard from "./pages/VendorDashboard/VendorDashboard";
import UploadProduct from "./pages/UploadProduct/UploadProduct";
import ProductSpecs from "./pages/ProductSpecs/ProductSpecs";
import { AuthProvider } from 'react-auth-kit'
import { RegistrationProvider } from "./pages/context/RegistrationContext";
import { VendorProvider } from './pages/context/VendorContext';
import { useSelector } from "react-redux";
import NotFound from './pages/NotFound/NotFound';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};

const UserProfilePage = () => {
  const user = useSelector((state) => state.userReducer.userInfo);
  return (
    <>
      {typeof user.id === 'number' ? (
        <>
          <Header />
          <UserDashboard />
          <Footer />
          <FooterBottom />
        </>) : <LogIn />}
    </>)
}


const VendorProfilePage = () => {
  return (
    <>
      <Header />
      <VendorDashboard />
      <Footer />
      <FooterBottom />
    </>)
}

const UploadProductPage = () => {
  return (
    <>
      <Header />
      <UploadProduct />
      <Footer />
      <FooterBottom />
    </>)
}

const ProductSpecsPage = () => {
  return (
    <>
      <Header />
      <ProductSpecs />
      <Footer />
      <FooterBottom />
    </>)
}


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/journal" element={<Journal />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/login" element={<LogIn />}></Route>
      <Route path="/vendors" element={<Vendors />}></Route>
      <Route path="/profile" element={<UserProfilePage />}></Route>
      <Route path="/vendorsignin" element={<VendorSignIn />}></Route>
      <Route path="/vendor-dashboard" element={<VendorProfilePage />}></Route>
      <Route path="/upload-product" element={<UploadProductPage />}></Route>
      <Route path="/product-specs" element={<ProductSpecsPage />}></Route>
      <Route path="/*" element={<NotFound />}></Route>

    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <AuthProvider authType={'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}>
        <VendorProvider>
          <RegistrationProvider>
            <Elements stripe={stripePromise}>
              <RouterProvider router={router} />
            </Elements>
          </RegistrationProvider>
        </VendorProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
