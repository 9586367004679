import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: 
   { id: 0,
    email: "",
    firstname: "",
    lastname: "",
    is_active: false,
    is_staff: false,
    phone_number: 0,
    date_of_birth: '',
    role: {
      id: 0,
      name: ""
    },
    verified: false,
 },
};

export const getblackUserSlice = createSlice({
  name: "getblack",
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
        state.userInfo = action.payload;
    },
    removeUserInfo: (state) => {
      state.userInfo = {}
    },
    updateUserInfo: (state, action) => {

      const payload = action.payload
      console.log({payload});
      state.userInfo = {...state.userInfo, ...payload}
    },
    getUserInfo: (state, action) => {
      state.products = state.products.filter(
        (item) => item._id !== action.payload
      );
    },
  },
});

export const {
  addUserInfo,
  getUserInfo,
  removeUserInfo,
  updateUserInfo,
} = getblackUserSlice.actions;
export default getblackUserSlice.reducer;
