import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { FaSearch, FaUser, FaCaretDown, FaShoppingCart } from 'react-icons/fa';
import Flex from '../../designLayouts/Flex';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { paginationItems } from '../../../constants';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeUserInfo } from '../../../redux/userSlice';

const HeaderBottom = () => {
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const signOut = useSignOut();
  const products = useSelector((state) => state.productReducer.products);
  const user = useSelector((state) => state.userReducer.userInfo);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current.contains(e.target)) {
        if (!show) setShow(true); // Update state only when necessary
      } else {
        if (show) setShow(false); // Update state only when necessary
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    // Cleanup
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [show, ref]);

  const handleSignOut = () => {
    signOut();
    dispatch(removeUserInfo());
  };

  // useEffect(() => {
  //   document.body.addEventListener("click", (e) => {
  //     if (ref.current.contains(e.target)) {
  //       setShow(true);
  //     } else {
  //       setShow(false);
  //     }
  //   });
  // }, [show, ref]);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [setShowSearchBar] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filtered = paginationItems.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredProducts(filtered);
  }, [searchQuery]);

  return (
    <div className='w-full bg-[#F5F5F3] relative'>
      <div className='max-w-container mx-auto'>
        <Flex className='flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24'>
          <div
            onClick={() => setShow(!show)}
            ref={ref}
            className='flex h-14 cursor-pointer items-center gap-2 text-primeColor'
          >
            <HiOutlineMenuAlt4 className='w-5 h-5' />
            <p className='text-[14px] font-normal'>Shop by Category</p>

            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='absolute top-36 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6'
              >
                <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                  Food
                </li>
                <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                  Service
                </li>
                {/* <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Accessories
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Furniture
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Electronics
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Fashion
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Health & Beauty
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Baby Toys
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Groceries
                </li>
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Free Stuffs
                </li> */}
              </motion.ul>
            )}
          </div>
          <div className='relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl'>
            <input
              className='flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]'
              type='text'
              onChange={handleSearch}
              value={searchQuery}
              placeholder='Search your products here'
            />
            <FaSearch className='w-5 h-5' />
            {searchQuery && (
              <div
                className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {searchQuery &&
                  filteredProducts.map((item) => (
                    <div
                      onClick={() =>
                        navigate(`/product/${item.productName.toLowerCase().split(' ').join('')}`, {
                          state: {
                            item: item,
                          },
                        }) &
                        setShowSearchBar(true) &
                        setSearchQuery('')
                      }
                      key={item._id}
                      className='max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3'
                    >
                      <img className='w-24' src={item.img} alt='productImg' />
                      <div className='flex flex-col gap-1'>
                        <p className='font-semibold text-lg'>{item.productName}</p>
                        <p className='text-xs'>{item.des}</p>
                        <p className='text-sm'>
                          Price:{' '}
                          <span className='text-primeColor font-semibold'>€{item.price}</span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className='flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative'>
            {/* This will display the user's first name if they're authenticated */}

            {auth() && auth().firstname && (
              <div className='flex text-base text-primeColor gap-2 mt-2 lg:mt-0 items-center pr-6 relative'>
                <p className=''>{user.firstname}</p>
              </div>
            )}
            <div onClick={() => setShowUser(!showUser)} className='flex'>
              {/* {auth().user} */}
              <FaUser />
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='absolute top-6 left-0 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6'
              >
                {auth() && auth().id ? (
                  ''
                ) : (
                  <>
                    <Link to='/login'>
                      <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                        Login
                      </li>
                    </Link>
                    <Link onClick={() => setShowUser(false)} to='/signup'>
                      <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                        Sign Up
                      </li>
                    </Link>

                    <Link to='/vendors'>
                      <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                        Vendors
                      </li>
                    </Link>
                  </>
                )}
                {/* {auth() && user && user.role && user.role.name === 'Customer' && (
                  <Link to="/profile">
                    <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer">
                      Profile
                    </li>
                  </Link>)} */}
                {/* {user && user.role.name === 'Vendor' && <Link to="/vendors">
                <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                  Vendors
                </li>
                </Link>} */}
                {/* {auth() && auth().id ?

                  <li className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer">
                    <button onClick={() => {
                      handleSignOut();
                      toast.success("Successfully logged out!");
                    }}>Sign Out</button>
                  </li>
                  : ''} */}

                {auth() && auth().id ? (
                  <>
                    <Link to='/profile'>
                      <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                        Profile
                      </li>
                    </Link>

                    <li className='text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer'>
                      <button
                        onClick={() => {
                          handleSignOut();
                          toast.success('Successfully logged out!');
                        }}
                      >
                        Sign Out
                      </button>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </motion.ul>
            )}
            <Link to='/cart'>
              <div className='relative'>
                <FaShoppingCart />
                <span className='absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white'>
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
        <ToastContainer position='top-right' autoClose={3000} closeOnClick />
      </div>
    </div>
  );
};

export default HeaderBottom;
