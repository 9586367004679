import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import UploadProduct from './UploadProduct';
// import ProductSpecs from './ProductSpecs';
import ProductsTable from './ProductsTable';

const AdminProductPage = () => {
  // const [products, setProducts] = useState([]); // List of products
  // const [productName, setProductName] = useState('');
  // const [productPrice, setProductPrice] = useState('');
  // const [setProductImage] = useState(null);
  // const [selectedProductId, setSelectedProductId] = useState(null); // For editing purposes

  useEffect(() => {
    // Fetch products from the backend
    // For the sake of this example, I'll use mock data.
    // const mockProducts = [
    //   { id: 1, name: 'Product A', price: 100, imageUrl: 'path/to/imageA.jpg' },
    //   { id: 2, name: 'Product B', price: 200, imageUrl: 'path/to/imageB.jpg' },
    // ];
    // setProducts(mockProducts);
  }, []);

  return (
    <div className='p-8'>
      <div className='w-full flex items-center justify-between mb-8'>
        <h2 className='text-2xl font-bold'>Manage Products</h2>
        <Link to='/upload-product'>
          <button
            type='button'
            className='mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
          >
            Create Product
          </button>
        </Link>
      </div>

      <div>
        <ProductsTable />
      </div>
    </div>
  );
};

export default AdminProductPage;
