import React, { useState, useContext } from 'react';
import axios from 'axios';

const RegistrationContext = React.createContext();

function RegistrationProvider({ children }) {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [accepted_tos, setAccepted_tos] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');




  const registerUser = async (formData) => {
    try {
      const response = await axios.post('https://getblackapi-drf-e7648734260e.herokuapp.com/api/users/signup', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSuccessMessage('Registration successful!');
      setError('');

      console.log(response.data); // Handle the response as per your requirements
    } catch (error) {
      if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          console.error(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.error('Error', error.message);
      }

    }
  };


  const contextValues = {
    firstname,
    setFirstName,
    lastname,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    confirm_password,
    setConfirmPassword,
    accepted_tos,
    setAccepted_tos,
    registerUser,
    successMessage,
    error,
  };

  return (
    <RegistrationContext.Provider value={contextValues}>
      {children}
    </RegistrationContext.Provider>
  );
}

function useRegistration() {
  return useContext(RegistrationContext);
}

export { RegistrationContext, RegistrationProvider, useRegistration };


