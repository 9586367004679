import React, { useState } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { logoLight } from '../../assets/images';
// import { useSignIn } from 'react-auth-kit'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { addVendorInfo, addVendorToken } from '../../redux/vendorSlice';

const VendorSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const signIn = useSignIn()
  // const [showPass, setShowPass] = useState(false);
  const [successMsg] = useState('');
  const [errEmail] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  // const vendor = useSelector((state) => state.vendorReducer.vendorInfo);

  const [errPassword] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // const handleShowPass = () => {
  //   if (showPass === false) {
  //     setShowPass(true);
  //   } else {
  //     setShowPass(false);
  //   }
  // };

  // useEffect(()=>{
  //   if(vendor.id >= 1){
  //     history("/login");
  //   }
  // }, [history])

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      email,
      password,
    };

    try {
      const response = await axios.post(
        'https://getblackapi-drf-e7648734260e.herokuapp.com/api/vendor/login/',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.error) {
        toast.error(response.data.error);
      }

      if (response.data.access_token) {
        dispatch(addVendorToken(response.data.access_token));
      }

      if (response.data.vendor) {
        dispatch(addVendorInfo({ ...response.data.vendor }));
        toast.success('Successfully logged in!');
        navigate('/vendor-dashboard');
      }
    } catch (error) {
      console.error('Login failed:', error);
      toast.error('Login failed. Please try again.'); // Show error toast if there's an issue
      throw error;
    }
  };
  //   // ============= Initial State Start here =============
  //   const [email, setEmail] = useState("");
  //   const [password, setPassword] = useState("");
  //   // ============= Initial State End here ===============
  //   // ============= Error Msg Start here =================
  //   const [errEmail, setErrEmail] = useState("");
  //   const [errPassword, setErrPassword] = useState("");

  //   // ============= Error Msg End here ===================
  //   const [successMsg, setSuccessMsg] = useState("");
  //   // ============= Event Handler Start here =============
  //   const handleEmail = (e) => {
  //     setEmail(e.target.value);
  //     setErrEmail("");
  //   };
  //   const handlePassword = (e) => {
  //     setPassword(e.target.value);
  //     setErrPassword("");
  //   };
  //   // ============= Event Handler End here ===============
  //   const handleSignUp = (e) => {
  //     e.preventDefault();

  //     if (!email) {
  //       setErrEmail("Enter your email");
  //     }

  //     if (!password) {
  //       setErrPassword("Create a password");
  //     }
  //     // ============== Getting the value ==============
  //     if (email && password) {
  //       setSuccessMsg(
  //         `Hello dear, Thank you for your attempt. We are processing to validate your access. Till then stay connected and additional assistance will be sent to you by your mail at ${email}`
  //       );
  //       setEmail("");
  //       setPassword("");
  //     }
  //   };
  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <div className='w-1/2 hidden lgl:inline-flex h-full text-white'>
        <div className='w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center'>
          <Link to='/'>
            <img src={logoLight} alt='logoImg' className='w-28' />
          </Link>
          <div className='flex flex-col gap-1 -mt-1'>
            <h1 className='font-titleFont text-xl font-medium'>Stay sign in for more</h1>
            <p className='text-base'>When you sign in, you are with us!</p>
          </div>
          <div className='w-[300px] flex items-start gap-3'>
            <span className='text-green-500 mt-1'>
              <BsCheckCircleFill />
            </span>
            <p className='text-base text-gray-300'>
              <span className='text-white font-semibold font-titleFont'>
                Get started fast with GetBlack
              </span>
              <br />
              Dive deep, explore the vastness, and make the most of everything GetBlack has to
              offer. Your journey to excellence starts here! 🖤🚀
            </p>
          </div>
          <div className='w-[300px] flex items-start gap-3'>
            <span className='text-green-500 mt-1'>
              <BsCheckCircleFill />
            </span>
            <p className='text-base text-gray-300'>
              <span className='text-white font-semibold font-titleFont'>
                Access all GetBlack services
              </span>
              <br />
              We’re proud to offer a diverse range of services tailored to meet your every need.
            </p>
          </div>
          <div className='w-[300px] flex items-start gap-3'>
            <span className='text-green-500 mt-1'>
              <BsCheckCircleFill />
            </span>
            <p className='text-base text-gray-300'>
              <span className='text-white font-semibold font-titleFont'>
                Trusted by online Shoppers
              </span>
              <br />
              We make it incredibly easy to shop and get your desired traditional products delivered
              to you.
            </p>
          </div>
          <div className='flex items-center justify-between mt-10'>
            <Link to='/'>
              <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
                © GetBlack
              </p>
            </Link>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              Terms
            </p>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              Privacy
            </p>
            <p className='text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300'>
              Security
            </p>
          </div>
        </div>
      </div>
      <div className='w-full lgl:w-1/2 h-full'>
        {successMsg ? (
          <div className='w-full lgl:w-[500px] h-full flex flex-col justify-center'>
            <p className='w-full px-4 py-10 text-green-500 font-medium font-titleFont'>
              {successMsg}
            </p>
            <Link to='/signup'>
              <button
                className='w-full h-10 bg-primeColor text-gray-200 rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300'
              >
                Sign Up
              </button>
            </Link>
          </div>
        ) : (
          <form className='w-full lgl:w-[450px] h-screen flex items-center justify-center'>
            <div className='px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor'>
              <h1 className='font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4'>
                Sign in as a Vendor
              </h1>
              <p className='font-titleFont text-base font-semibold text-gray-600'>
                Welcome back, continue into your account with your email and password.
              </p>
              <br></br>

              <div className='flex flex-col gap-3'>
                {/* Email */}
                <div className='flex flex-col gap-.5'>
                  <p className='font-titleFont text-base font-semibold text-gray-600'>Email:</p>
                  <input
                    onChange={handleEmailChange}
                    value={email}
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='email'
                    placeholder='john@gmail.com'
                  />
                  {errEmail && (
                    <p className='text-sm text-red-500 font-titleFont font-semibold px-4'>
                      <span className='font-bold italic mr-1'>!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Password */}
                <div className='flex flex-col gap-.5'>
                  <p className='font-titleFont text-base font-semibold text-gray-600'>Password:</p>
                  <input
                    onChange={handlePasswordChange}
                    value={password}
                    className='w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none'
                    type='password'
                    placeholder='password'
                  />
                  {errPassword && (
                    <p className='text-sm text-red-500 font-titleFont font-semibold px-4'>
                      <span className='font-bold italic mr-1'>!</span>
                      {errPassword}
                    </p>
                  )}
                </div>

                <button
                  onClick={handleSubmit}
                  className='bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300'
                >
                  Sign In
                </button>
                <p className='text-sm text-center font-titleFont font-medium'>
                  Do not have an Account?
                  <Link to='/signup'>
                    <span className='hover:text-blue-600 duration-300'>Sign up</span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        )}
      </div>
      <ToastContainer position='top-right' autoClose={10000} closeOnClick />
    </div>
  );
};

export default VendorSignIn;
