import React, { useState } from "react";
import { Link } from "react-router-dom";

const UserAccountSettings = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [primaryAddress, setPrimaryAddress] = useState("");
    const [secondaryAddress, setSecondaryAddress] = useState("");
    const [creditCard, setCreditCard] = useState("");
    const [language, setLanguage] = useState("English");
    const [region, setRegion] = useState("US");

    // const navigate = useNavigate();

    const handleSaveChanges = (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        // Submit changes logic here, like API calls...

        alert("Changes saved successfully!");
    };

    return (
        <div className="bg-gray-100 p-8">
            <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
            
            <form className="bg-white p-6 rounded-md shadow-md" onSubmit={handleSaveChanges}>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-600">Email:</label>
                    <input 
                        id="email" 
                        type="email" 
                        placeholder="Change your email" 
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password:</label>
                    <input 
                        id="password" 
                        type="password" 
                        placeholder="Change your password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">Confirm Password:</label>
                    <input 
                        id="confirmPassword" 
                        type="password" 
                        placeholder="Confirm your new password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="primaryAddress" className="block text-sm font-medium text-gray-600">Primary Address:</label>
                    <textarea 
                        id="primaryAddress" 
                        placeholder="Enter primary address"
                        value={primaryAddress}
                        onChange={e => setPrimaryAddress(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="secondaryAddress" className="block text-sm font-medium text-gray-600">Secondary Address (optional):</label>
                    <textarea 
                        id="secondaryAddress" 
                        placeholder="Enter secondary address"
                        value={secondaryAddress}
                        onChange={e => setSecondaryAddress(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="creditCard" className="block text-sm font-medium text-gray-600">Credit Card (last 4 digits):</label>
                    <input 
                        id="creditCard" 
                        type="text" 
                        placeholder="**** **** **** 1234"
                        maxLength={4}
                        value={creditCard}
                        onChange={e => setCreditCard(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    />
                </div>

                
                <h3 className="text-xl font-medium my-4">Language & Regional Settings</h3>
                <div className="mb-4">
                    <label htmlFor="language" className="block text-sm font-medium text-gray-600">Language:</label>
                    <select 
                        id="language" 
                        value={language}
                        onChange={e => setLanguage(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    >
                        <option value="English">English</option>
                        <option value="Spanish">Spanish</option>
                        <option value="French">French</option>
                        {/* Add other languages here */}
                    </select>
                </div>
                <div className="mb-4">
                    <label htmlFor="region" className="block text-sm font-medium text-gray-600">Region:</label>
                    <select 
                        id="region" 
                        value={region}
                        onChange={e => setRegion(e.target.value)}
                        className="mt-1 p-2 w-full border rounded-md"
                    >
                        <option value="US">US</option>
                        <option value="UK">UK</option>
                        <option value="FR">France</option>
                        {/* Add other regions here */}
                    </select>
                </div>




                <div className="flex justify-between mt-4">
                    <button 
                        type="submit" 
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-800 active:bg-blue-900"
                    >
                        Save Changes
                    </button>

                    <Link 
                        to="/delete-account" 
                        className="text-red-600 hover:text-red-700 focus:outline-none focus:text-red-800"
                    >
                        Delete Account
                    </Link>



                </div>
            </form>
        </div>
    );
};

export default UserAccountSettings;
