import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../redux/userSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfilePage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userReducer.userInfo);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [successMessage] = useState("");
    const [errorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setFirstName(user?.firstname !== undefined ? user.firstname : '');
        setLastName(user?.lastname !== undefined ? user.lastname : '');
        setPhoneNumber(user?.phone_number !== undefined ? user.phone_number : '');
        setDateOfBirth(user?.date_of_birth !== undefined ? user.date_of_birth : '');
    }, [user]); // Added user to the dependency array

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true); // Set isSubmitting to true before API call

        const data = {
            firstname: firstName,
            lastname: lastName || null,
            phone_number: phoneNumber || null,
            date_of_birth: dateOfBirth || null
        };

        try {
            const response = await axios.put(`https://getblackapi-drf-e7648734260e.herokuapp.com/api/users/${user?.id}/update`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch(updateUserInfo({ ...response.data.updated_user }));
            toast.success("Profile Updated Successfully!");
        } catch (err) {
            console.log({ err });
            const errorMessage = err.response?.data?.message || "There was an error updating your profile.";
            toast.error(errorMessage); // Use toast for error
        } finally {
            setIsSubmitting(false); // Set isSubmitting to false after API call
        }
    };

    return (
        <div className="flex flex-col items-center p-6">
            {successMessage && <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                <p>{successMessage}</p>
            </div>}

            {errorMessage && <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                <p>{errorMessage}</p>
            </div>}

            <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
                                <label className="block mb-4">
                    <span className="text-gray-700">First Name:</span>
                    <input
                        type="text"
                        label={user.firstname || firstName}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="mt-2 p-2 w-full border rounded-md"
                    />
                </label>
                <label className="block mb-4">
                    <span className="text-gray-700">Last Name:</span>
                    <input
                        type="text"
                        label={user.lastname || lastName}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="mt-2 p-2 w-full border rounded-md"
                    />
                </label>
                <label className="block mb-6">
                    <span className="text-gray-700">Phone Number:</span>
                    <input
                        type="tel"
                        label={user.phone_number || phoneNumber}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="mt-2 p-2 w-full border rounded-md"
                    />
                </label>


                <label className="block mb-4">
                    <span className="text-gray-700">Date of birth:</span>
                    <input
                        type="date" // Changed input type to date
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        className="mt-2 p-2 w-full border rounded-md"
                    />
                </label>
                <div className="flex justify-between">
                    <button 
                        onClick={handleSubmit} 
                        disabled={isSubmitting} 
                        className={isSubmitting ? "bg-grey-600 text-white px-4 py-2 rounded focus:outline-none cursor-progress" : "bg-green-600 text-white px-4 py-2 rounded hover:bg-red-700 focus:outline-none focus:bg-red-800 active:bg-red-900"}>
                        Save
                    </button>
                </div>
                <ToastContainer position="top-right" autoClose={3000} closeOnClick />
            </div>
        </div>
    );
};

export default ProfilePage;
