import React, { useState, useEffect } from 'react';

const VendorTransactions = ({ transactionId }) => {
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    // Fetch the transaction details from your API using the transactionId.
    // For now, I'll use mock data.
    const mockData = {
      id: '12345',
      date: '2023-09-25',
      totalAmount: 150.0,
      items: [
        { name: 'Product A', price: 50.0, quantity: 2 },
        { name: 'Product B', price: 50.0, quantity: 1 },
      ],
      paymentMethod: 'Credit Card',
      customer: {
        name: 'John Doe',
        email: 'john.doe@example.com',
      },
    };

    setTransaction(mockData);
  }, [transactionId]);

  if (!transaction) {
    return <div>Loading...</div>;
  }

  return (
    <div className='p-6 bg-white shadow-md rounded-md'>
      <h2 className='text-2xl font-bold mb-4'>Transaction Detail</h2>

      <div className='mb-4'>
        <strong>Date:</strong> {transaction.date}
      </div>
      <div className='mb-4'>
        <strong>Total Amount:</strong> ${transaction.totalAmount.toFixed(2)}
      </div>
      <div className='mb-4'>
        <strong>Payment Method:</strong> {transaction.paymentMethod}
      </div>
      <div className='mb-4'>
        <strong>Customer:</strong> {transaction.customer.name} ({transaction.customer.email})
      </div>

      <div className='mb-4'>
        <strong>Items Purchased:</strong>
        <ul className='list-disc pl-6'>
          {transaction.items.map((item, index) => (
            <li key={index}>
              {item.name} - ${item.price.toFixed(2)} x {item.quantity} = $
              {item.price * item.quantity}
            </li>
          ))}
        </ul>
      </div>

      {/* Include other transaction details as needed */}
    </div>
  );
};

export default VendorTransactions;
